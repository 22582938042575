import { AuthLayout, Login } from '@frontend/feature-auth';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import React, { ReactElement } from 'react';

import type { NextPageWithLayout } from './_app';

const LoginPage: NextPageWithLayout = ({ type }) => {
  const { publicRuntimeConfig } = getConfig();
  return (
    <>
      <Head>
        <title>Login</title>
        <meta name="description" content="" />
        <link
          rel="icon"
          href={`${publicRuntimeConfig.staticFolder}/favicon.ico`}
        />
      </Head>
      <Login type={type as string} />
    </>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  return {
    props: {
      type: context.query['type'] || null,
    },
  };
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>;
};

export default LoginPage;
